import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { NotificationsComponent } from '../notifications/NotificationsComponent';
import { LoaderComponent } from '../loader/LoaderComponent';
import { HeaderLogin } from './headerLogin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import { HeaderSearch } from './headerSearch';

interface MenuItem {
  label: string;
  url: string;
  target?: string,
  menuItems: MenuItem[];
}

interface HeaderData {
  cookieLinkUrl: string;
  cookietext: string;
  skipToMainContentText: string;
  adBannerImageUrl: string;
  adBannerImageAlt: string;
  adBannerUrl: string;
  isStartPage: boolean;
  userOrganizationName: string;
  username: string;
  forgotPasswordUrl: string;
  userSettingsUrl: string;
  searchPageUrl: string;
  login: MenuItem;
  cartSize: number;
  showCart: boolean;
  menuItems: MenuItem[];
  labels: any;
}

declare global {
  interface Bokinfo {
    headerData: any;
  }
}

export const Header = () => {
  const [headerData, setHeaderData] = useState<HeaderData>();

  const cartSize = useSelector((state: RootState) => state.cart.size);

  useEffect(() => {
    if (
      window.bokinfo.headerData !== undefined &&
      window.bokinfo.headerData !== ''
    ) {
      setHeaderData(window.bokinfo.headerData as HeaderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    headerData?.cartSize && dispatch(setCartSize(headerData.cartSize));
  }, [headerData, dispatch]);

  return (
    <>
      {headerData !== undefined && (
        <a
          className="visually-hidden-focusable d-flex justify-content-center skip-content"
          href="#PageContent"
        >
          {headerData.skipToMainContentText}
        </a>
      )}
      {headerData !== undefined &&
        headerData.adBannerImageUrl !== undefined &&
        headerData.adBannerImageUrl !== '' &&
        headerData.adBannerImageUrl !== null && (
          <div className="container">
            <a href={headerData.adBannerUrl}>
              <img
                className="img-fluid d-flex mx-auto img-ad"
                src={headerData.adBannerImageUrl}
                alt={headerData.adBannerImageAlt}
              />
            </a>
          </div>
        )}
      <Navbar
        expand="lg"
        className={`${!headerData?.username ? 'navbar--thin' : ''}`}
      >
        <Container className="flex-wrap align-items-end">
          <Col md={12} className="w-100">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand
              className="ms-3 ms-lg-0 d-inline-block d-lg-none"
              href="/"
            >
              <img
                className="img-fluid"
                src="/images/bokinfo_logo_svart.png"
                alt="logo"
              />
            </Navbar.Brand>
            {headerData !== undefined && headerData.login && (
              <>
                <div className="position-relative d-inline-block float-end mb-1">
                  <HeaderLogin
                    labels={headerData.labels}
                    mobile={true}
                    userOrganizationName={headerData.userOrganizationName}
                    userName={headerData.username}
                    forgotPasswordUrl={headerData.forgotPasswordUrl}
                    userSettingsUrl={headerData.userSettingsUrl}
                  />
                </div>
                <div className="position-relative d-inline-block float-end mb-1 me-2 me-md-4">
                  <Nav.Link
                    className="mobile-icon-item ms-auto d-block d-lg-none navbar-brand"
                    href="/sv-SE/kundvagn"
                  >
                    <i className="bi bi-cart2" />
                    {cartSize > 0 && (
                      <span
                        className="position-absolute top-50 start-20 translate-middle badge rounded-pill"
                        style={{
                          fontWeight: 500,
                          background: '#EFF8FF',
                          color: '#4075C8',
                        }}
                      >
                        {cartSize}
                      </span>
                    )}
                  </Nav.Link>
                </div>
              </>
            )}
            <Navbar.Collapse id="basic-navbar-nav">
              <Navbar.Brand
                className="ms-3 ms-lg-0 d-none d-lg-inline-block"
                href="/"
              >
                <img
                  className="img-fluid"
                  src="/images/bokinfo_logo_svart.png"
                  alt="logo"
                />
              </Navbar.Brand>
              {headerData !== undefined && headerData.menuItems.length > 0 && (
                <Nav className="me-auto menu flex-wrap">
                  {headerData.menuItems.map((item, index) => {
                    return item.menuItems ? (
                      <NavDropdown
                        key={"megamenudrop" + item.label + index.toString()}
                        title={item.label}
                        className="megaMenu"
                      >
                        <Row>
                          {item.menuItems.map(
                            (megaMenuItem: MenuItem, index: number) => {
                              return megaMenuItem.menuItems.length > 0 ? (
                                <Col
                                  key={"mega" + megaMenuItem.label + index.toString()}
                                  sm={12}
                                  md={12}
                                  lg={3}
                                  className="mt-0"
                                >
                                  <div
                                    className={`col-megaMenu ${index === 0
                                        ? 'col-megaMenu--grey col-megaMenu--no-border '
                                        : index === item.menuItems.length - 1
                                          ? 'col-megaMenu--no-border'
                                          : ''
                                      }`}
                                  >
                                    <ul className="list-unstyled">
                                      {megaMenuItem.menuItems.map(
                                        (
                                          subMegaMenuItem: MenuItem,
                                          index: number
                                        ) => {
                                          return (
                                            <li
                                              className="list-item"
                                              key={"sub" + subMegaMenuItem.label + index.toString()}
                                            >
                                              <a target={subMegaMenuItem.target} href={subMegaMenuItem.url}>
                                                {subMegaMenuItem.label}
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </Col>
                              ) : (
                                null
                              )
                            }
                          )}
                        </Row>
                      </NavDropdown>
                    ) : (
                      <Nav.Link
                        key={item.label + index.toString()}
                        className="menu__item"
                        href={item.url}
                      >
                        {item.label}
                      </Nav.Link>
                    );
                  })}
                </Nav>
              )}
              {headerData !== undefined && headerData.login && (
                <div className="position-relative d-flex">
                  {headerData.showCart && (
                    <Nav.Link
                      className="btn btn-secondary ms-auto d-none d-lg-block position-relative me-3"
                      href="/sv-SE/kundvagn"
                    >
                      <i className="bi bi-cart2" />
                      {cartSize > 0 && (
                        <span
                          className="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                          style={{
                            fontWeight: 500,
                            background: '#EFF8FF',
                            color: '#4075C8',
                          }}
                        >
                          {cartSize}
                        </span>
                      )}
                    </Nav.Link>
                  )}
                  <HeaderLogin
                    labels={headerData.labels}
                    mobile={false}
                    userOrganizationName={headerData.userOrganizationName}
                    userName={headerData.username}
                    forgotPasswordUrl={headerData.forgotPasswordUrl}
                    userSettingsUrl={headerData.userSettingsUrl}
                  />
                </div>
              )}
            </Navbar.Collapse>
          </Col>
          {headerData?.username && headerData?.username !== '' && (
            <HeaderSearch
              labels={headerData.labels}
              searchPageUrl={headerData.searchPageUrl}
            />
          )}
        </Container>
      </Navbar>
      <NotificationsComponent />
      <LoaderComponent />
    </>
  );
};
