import { Modal, Tab } from 'bootstrap';
import { createOrUpdate } from '../../services/returnRequestService';
import { addItem } from '../../services/CartService';
import { NotificationTypes } from "../../typescript/enums/enums";
import NotificationClass from '../../typescript/classes/NotificationClass';
import { store } from '../../redux/store';
import { setCartSize } from '../../redux/reducers/cartSlice.reducer';
import { sendErrorDescription } from '../../services/bookDetailsService';

export default class productPageClass {
    static init() {
        const produtPage = document.getElementById('product-page');

        if (produtPage) {
            const productPage = new productPageClass();

            productPage.setFocusOnMainSearch();
        }
    }

    constructor() {
        this.initTabs();
        this.enableDistributorSwitch();
        //this.enableReturnRequests();
        this.enableAddItemToCart();
        this.enableReportError();
    }

    initTabs() {
        var triggerTabList = [].slice.call(
            document.querySelectorAll('#product-tabs a')
        );
        triggerTabList.forEach(function (triggerEl: Element) {
            var tabTrigger = new Tab(triggerEl);

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault();
                tabTrigger.show();
            });
        });
    }

    enableReportError() {
        
        const reportErrorButton = document.getElementById('report-error-button');
        const catalogEntryCode = reportErrorButton?.getAttribute('data-code') as string;
        
        reportErrorButton?.addEventListener('click', function (event) {
            const errorDescription = (document.getElementById('errormessage') as HTMLTextAreaElement)?.value || ''
            if (errorDescription.length < 4) {
                document.getElementById('errormessage')?.classList.add("u-border-error");
                document.getElementById('errormessage-empty')?.classList.remove("d-none");
                return;
            }

            sendErrorDescription(catalogEntryCode, errorDescription).then(res => {
                res && NotificationClass.createNotification({
                    type: NotificationTypes.Success,
                    message: 'Meddelandet har skickats!'
                });

                !res && NotificationClass.createNotification({
                    type: NotificationTypes.Error,
                    message: 'Meddelandet kunde inte skickas!'
                });

                (document.getElementById('errormessage') as HTMLTextAreaElement).value = "";
                let modal = Modal.getInstance(document.getElementById('reportErrorModal') as any)
                modal?.hide();
            });
        })

    }

    enableAddItemToCart() {
        const addItemElement = document.getElementById('add-to-cart');

        addItemElement?.addEventListener('click', () => {
            const distributors = document.querySelectorAll(`[data-distributor-trigger]`);
            let selectedDistributor = -1;
            let selectedDistributorShort = "";

            distributors.forEach((el: Element) => {
                if ((el as HTMLInputElement).checked) {
                    let parseValue = el.getAttribute('data-distributor-trigger');
                    if (parseValue) {
                        selectedDistributor = Number.parseInt(parseValue);
                    }
                    selectedDistributorShort = el.id;
                }
            });
            
            const catalogEntryCode = addItemElement.getAttribute('data-code');
            const quantity = Number.parseInt((document.getElementById('quantity-input') as HTMLInputElement)?.value) || 1;
            const reference = (document.getElementById('addToCartReference') as HTMLInputElement)?.value || '';

            if (selectedDistributor > 0 && catalogEntryCode) {
                addItem(catalogEntryCode, quantity, selectedDistributor, selectedDistributorShort, reference)
                    .then((res) => {
                        NotificationClass.createNotification({
                            type: NotificationTypes.Success,
                            message: 'Artikeln lades till i korgen'
                        });
                        store.dispatch(setCartSize(res?.data
                            .map((p: any) => p.amount)
                            .reduce((p: any, c: any) => p + c, 0)))
                    }).catch(() => NotificationClass.createNotification({
                        type: NotificationTypes.Error,
                        message: 'Artikeln kunde inte läggas i korgen!'

                    }));
            }

            this.setFocusOnMainSearch();
        });
    }

    enableDistributorSwitch() {
        const elements = [].slice.call(
            document.querySelectorAll('[data-distributor-trigger]')
        );

        elements.forEach((el: Element) => {
            el.addEventListener('change', function (event) {
                event.preventDefault();
                const trigger = el.getAttribute('data-distributor-trigger');
                const targets = document.querySelectorAll(`[data-distributor-target]`);

                targets.forEach((el: Element) => {
                    el.getAttribute('data-distributor-target') === trigger
                        ? el.classList.remove('d-none')
                        : el.classList.add('d-none');
                });
            });
        });
    }

    enableReturnRequests() {
        const returnRequestModal = new Modal('#returnRequestModal');

        const form = document.querySelector('#returnRequestForm');

        const splitPath = window.location.pathname.split('/').filter((p) => p);
        const articleNumber = splitPath[splitPath.length - 1];

        form?.addEventListener('submit', (e) => {
            e.preventDefault();

            const { amount, distributors } = (e.target as any).elements;
            const distributorArray: any[] = Array.from(distributors);

            createOrUpdate(
                articleNumber,
                distributorArray.filter((d: any) => d.checked)[0].value,
                amount.value
            )
                .then((res) => {
                    console.log(res);
                    returnRequestModal.hide();
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }
    
    setFocusOnMainSearch() {
        const mainSearchTextbox: HTMLInputElement = document.getElementById("main-search-textbox") as HTMLInputElement;

        if (mainSearchTextbox) {
            mainSearchTextbox.focus();
            mainSearchTextbox.select();
        };

    }
}
