import { ReactNode } from 'react';

export interface TableColumn {
	title?: string;
	width: string;
	truncated?: boolean;
	key?: string;
}

export interface TableItem {
	columns: ReactNode[];
}

export interface ITable {
	width?: number;
	columns: TableColumn[];
	items: TableItem[];
	smallTableHeadFont?: boolean;
	firstColumnBold?: boolean;
	striped?: string | boolean;
	verticalAlignTop?: boolean;
	columnGap?: number;
	rowHeight?: number;
	hideHeader?: boolean;
	headerBorder?: boolean;
	boldHeader?: boolean;
	fallback?: string;
	rowsWithError?: number[];
}

export const Table = ({
	width,
	columns,
	items,
	smallTableHeadFont,
	firstColumnBold,
	striped,
	verticalAlignTop,
	columnGap,
	rowHeight,
	hideHeader,
	headerBorder,
	boldHeader,
	fallback,
	rowsWithError,
}: ITable) => {
	return (
		<>
			{items.length === 0 && fallback ? (
				<span className="d-block p-3 text-muted">{fallback}</span>
			) : (
				<div className="u-horizontal-scroll">
					<div style={{ minWidth: width }}>
						<div
							className={`small px-3 ${
								hideHeader ? '' : `py-${rowHeight || 3}`
							} d-flex ${columnGap ? `gap-${columnGap}` : ''} ${
								headerBorder ? 'border-bottom' : ''
							}`}
						>
							{columns.map((col) => {
								return (
									<div
										key={`col-${col.key ?? col.title}`}
										style={{ width: col.width }}
										className={boldHeader ? 'u-text-weight-medium' : ''}
									>
										{smallTableHeadFont ?? false ? (
											<span className="d-block">{col.title}</span>
										) : (
											<h6 className="h6">{col.title}</h6>
										)}
									</div>
								);
							})}
						</div>
						<div className=" gx-0 px-0">
							{items.map((item, cIdx) => {
								return (
									<div
										key={`tblrow-${cIdx}`}
										className={`d-flex gx-0 px-3 u-text-small-medium u-text-grey py-${
											rowHeight || 3
										} ${columnGap ? `gap-${columnGap}` : ''} ${
											verticalAlignTop ?? false
												? 'align-items-top'
												: 'align-items-center'
										}  ${
											striped && cIdx % 2 === (striped === 'odd' ? 1 : 0)
												? 'u-background-grey'
												: ''
										}	${
											rowsWithError && rowsWithError.includes(cIdx)
												? 'u-border-error'
												: ''
										}`}
									>
										<div className="d-none col-12">
											<div className=" justify-content-end">
												<div className="col-2">
													<i role="button" className="bi bi-pencil"></i>
												</div>
											</div>
										</div>
										{item.columns.map((node, index) => {
											if (columns.length <= index) return null;

											return (
												<div
													key={`tblrowcol-${index}`}
													style={{
														width: columns[index].width,
													}}
													className={`gx-0 ${
														(firstColumnBold ?? true) && index === 0 && 'fw-bold'
													} ${columns[index].truncated && 'text-truncate'}`}
													title={
														columns[index].truncated && typeof node === 'string'
															? node
															: undefined
													}
												>
													{node}
												</div>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
