import recoverPasswordClass from "./classes/recoverPasswordClass";
import changePasswordClass from "./classes/changePasswordClass";
import changePriceScaleClass from "./classes/changePriceScaleClass";
import productPageClass from "./classes/productPageClass";
import CollapserOverflowCheck from "./classes/CollapserOverflowCheck";
import { Tooltip } from "bootstrap";

window.onload = () => {
    init();
};

const init = () => {
    recoverPasswordClass.init();
    changePasswordClass.init();
    changePriceScaleClass.init();
    productPageClass.init();
    CollapserOverflowCheck.init();
    enableTooltips();
}

const enableTooltips = () => {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })
}

export { };