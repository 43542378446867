import { Card, Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { InformationIcon } from '../../shared/InformationIcon';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import { getChangedInformation, hasChanged, shouldBeLocked } from '../common';
import { t } from '../../../services/translationService';

interface Props {
	errors: any;
	register: any;
	control: any;
	getValues: any;
	setValue: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
}

export const Dates = ({
	errors,
	register,
	control,
	blockRef,
	pageVariant,
	lockedFields,
	changedProps,
}: Props) => {
	const validDate = (date: Date) => {
		if (!date) {
			return t('valideringDatum');
		}
	};
	const datepickerInvalid = (value: any) => {
		return value ? 'is-invalid' : '';
    };
    const showExtendedChangeInfo = pageVariant !== "publisher";

	return (
		<>
			<h3 ref={blockRef}>{t('datum')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						<Col xs={7}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('utgivningsdatum')}*
								</Form.Label>
								<Controller
									control={control}
									name={`utgivningsdatum`}
									rules={{ validate: { validDate } }}
									render={({ field }) => (
										<Col>
											<i className="align-self-center bi bi-calendar4 position-absolute ms-3 mt-1 pt-1 u-z-index-1" />
											<LockedOrChanged
												locked={shouldBeLocked(lockedFields, 'utgivningsdatum')}
                                                changed={hasChanged(changedProps, 'utgivningsdatum')}
                                                showExtendedInfo={showExtendedChangeInfo}
												changedInformation={getChangedInformation(
													changedProps,
													'utgivningsdatum'
												)}
												child={
													<DatePicker
														dateFormat={'yyyy-MM-dd'}
														placeholderText={t('yyyymmdd')}
														locale="sv"
														onChange={(date) =>
															field.onChange(date?.toLocaleDateString('sv-SE'))
														}
														selected={
															!field.value ? field.value : new Date(field.value)
														}
														className={`form-control ps-5 ${datepickerInvalid(
															errors.utgivningsdatum
														)}`}
													/>
												}
											/>
										</Col>
									)}
								/>
								{!!errors.utgivningsdatum && (
									<Form.Control.Feedback type="invalid" className="d-block">
										{`${errors.utgivningsdatum.message}`}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
						{pageVariant === 'large' && (
							<>
								<Col xs={7}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('fakturadatum')}
										</Form.Label>
										<Controller
											control={control}
											name={`fakturadatum`}
											render={({ field }) => (
												<Col>
													<i className="align-self-center bi bi-calendar4 position-absolute ms-3 mt-1 pt-1 u-z-index-1" />
													<LockedOrChanged
														locked={false}
                                                        changed={hasChanged(changedProps, 'fakturadatum')}
                                                        showExtendedInfo={showExtendedChangeInfo}
														changedInformation={getChangedInformation(
															changedProps,
															'fakturadatum'
														)}
														child={
															<DatePicker
																dateFormat={'yyyy-MM-dd'}
																placeholderText={t('yyyymmdd')}
																locale="sv"
																onChange={(date) =>
																	field.onChange(
																		date?.toLocaleDateString('sv-SE')
																	)
																}
																selected={
																	!field.value
																		? field.value
																		: new Date(field.value)
																}
																className={`form-control ps-5 ${datepickerInvalid(
																	errors.fakturadatum
																)}`}
															/>
														}
													/>
												</Col>
											)}
										/>
									</Form.Group>
								</Col>
								<Col xs={7}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('saljperiod')}{' '}
											<InformationIcon
												text={t('hjalpSaljPeriod')}
												inline={true}
											/>
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('saljperiod')}
										/>
									</Form.Group>
								</Col>
								<Col xs={7}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('reaar')}{' '}
											<InformationIcon text={t('hjalpReaar')} inline={true} />
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('reaar')}
										/>
									</Form.Group>
								</Col>
								<Col xs={7}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('forstaForsaljningsdag')}
										</Form.Label>
										<Controller
											control={control}
											name={`forstaForsaljningsdag`}
											render={({ field }) => (
												<Col>
													<i className="align-self-center bi bi-calendar4 position-absolute ms-3 mt-1 pt-1 u-z-index-1" />
													<DatePicker
														dateFormat={'yyyy-MM-dd'}
														placeholderText={t('yyyymmdd')}
														locale="sv"
														onChange={(date) =>
															field.onChange(date?.toLocaleDateString('sv-SE'))
														}
														selected={
															!field.value ? field.value : new Date(field.value)
														}
														className={`form-control ps-5`}
													/>
												</Col>
											)}
										/>
									</Form.Group>
								</Col>
								<Col xs={7}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('recensionsDag')}
										</Form.Label>
										<Controller
											control={control}
											name={`recensionsdag`}
											render={({ field }) => (
												<Col>
													<i className="align-self-center bi bi-calendar4 position-absolute ms-3 mt-1 pt-1 u-z-index-1" />
													<DatePicker
														dateFormat={'yyyy-MM-dd'}
														placeholderText={t('yyyymmdd')}
														locale="sv"
														onChange={(date) =>
															field.onChange(date?.toLocaleDateString('sv-SE'))
														}
														selected={
															!field.value ? field.value : new Date(field.value)
														}
														className={`form-control ps-5`}
													/>
												</Col>
											)}
										/>
									</Form.Group>
								</Col>
							</>
						)}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
