import { Card, Col, Form, Row } from 'react-bootstrap';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import { InformationIcon } from '../../shared/InformationIcon';
import { LockedOrChanged } from '../../shared/LockedOrChanged';
import {
    getBooleanOptions,
    getChangedInformation,
    getOptions,
    hasChanged,
    shouldBeLocked,
} from '../common';
import { t } from '../../../services/translationService';
import { useEffect } from 'react';

interface Props {
    errors: any;
    register: any;
    blockRef: any;
    setValue: any;
    getValues: any;
    pageVariant: string;
    lockedFields: keyValue[];
    changedProps: ChangedProperty[];
    watch: any;
}

export const Format = ({
    errors,
    register,
    blockRef,
    setValue,
    getValues,
    pageVariant,
    lockedFields,
    changedProps,
    watch,
}: Props) => {
    const watchMediatyp = watch('mediatyp');
    const showExtendedChangeInfo = pageVariant !== "publisher";
	let initialRender=true;

	useEffect(() => {
		if(initialRender){
			initialRender=false;
		} else{
			setValue('bandtyp', undefined);
			setValue('epubversion', undefined);
		}
	}, [watchMediatyp]);

	const getBandtypOptions = () => {
		if (watchMediatyp !== '' && watchMediatyp !== undefined) {
			const list = window.bokinfo.dictionaries['mediaTypes'];
			const result = list.filter((obj: any) => obj.key === watchMediatyp);
			if (result !== undefined && result.length > 0) {
				let bandTyper = result[0].formats.slice(0);
				bandTyper.unshift({
					key: '',
					value: t('valjEllips'),
				});
				return bandTyper.map((entry: any, index: number) => {
					return index === 0 ? (
						<option key={null} value={''}>
							{entry.value}
						</option>
					) : (
						<option key={entry.key} value={entry.value}>
							{entry.value}
						</option>
					);
				});
			}
		} else {
			return (
				<option key={''} value={''}>
					{t('valjEllips')}
				</option>
			);
		}
    };

	return (
		<>
			<h3 ref={blockRef}>{t('format')}</h3>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						{pageVariant !== 'quick' && (
							<Col xs={12}>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('mediatyp')}*
									</Form.Label>
									<LockedOrChanged
										locked={shouldBeLocked(lockedFields, 'mediatyp')}
                                        changed={hasChanged(changedProps, 'mediatyp')}
                                        showExtendedInfo={showExtendedChangeInfo}
										changedInformation={getChangedInformation(
											changedProps,
											'mediatyp'
										)}
										child={
											<Form.Control
												as={'select'}
												type="select"
												className="form-select"
												placeholder={t('valjEllips')}
												{...register('mediatyp', {
													required: t('valideringMediatyp'),
												})}
												isInvalid={!!errors.mediatyp}
											>
												{getOptions('mediaTypes')}
											</Form.Control>
										}
									/>

									{errors.mediatyp && (
										<Form.Control.Feedback type="invalid">
											{`${errors.mediatyp.message}`}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							</Col>
						)}
						<Col xs={12}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('bandtyp')}*
									<InformationIcon
										text={t('hjalpBandtyp')}
										inline={true}
										className="ps-1"
									/>
								</Form.Label>
								<LockedOrChanged
									locked={shouldBeLocked(lockedFields, 'bandtyp')}
                                    changed={hasChanged(changedProps, 'bandtyp')}
                                    showExtendedInfo={showExtendedChangeInfo}
									changedInformation={getChangedInformation(
										changedProps,
										'bandtyp'
									)}
									child={
										(
											Object.values(
												window.bokinfo.dictionaries.mediaTypes
											).find((m: any) => m.value === watchMediatyp) as any
										)?.allowFormatAsText ? (
											<Form.Control
												{...register('bandtyp')}
												placeholder={t('skrivEllips')}
											/>
										) : (
											<Form.Control
												defaultValue={getValues('bandtyp')}
												as={'select'}
												type="select"
												{...register('bandtyp', {
													required: t('valideringBandtyp'),
												})}
												className="form-select"
												placeholder={t('valjEllips')}
												isInvalid={!!errors.bandtyp}
											>
												{getBandtypOptions()}
											</Form.Control>
										)
									}
								/>
								{errors.bandtyp && (
									<Form.Control.Feedback type="invalid">
										{`${errors.bandtyp.message}`}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Col>
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('omfang')}
                                </Form.Label>
                                <LockedOrChanged
                                    locked={shouldBeLocked(lockedFields, 'omfang')}
                                    changed={hasChanged(changedProps, 'omfang')}
                                    showExtendedInfo={showExtendedChangeInfo}
                                    changedInformation={getChangedInformation(
                                        changedProps,
                                        'omfang'
                                    )}
                                    child={
                                        <Form.Control
                                            placeholder={t('skrivEllips')}
                                            {...register('omfang')}
                                            isInvalid={!!errors.omfang}
                                        />
                                    }/>
							</Form.Group>
						</Col>
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('omfangTyp')}
								</Form.Label>
								<LockedOrChanged
									locked={shouldBeLocked(lockedFields, 'omfangTyp')}
                                    changed={hasChanged(changedProps, 'omfangTyp')}
                                    showExtendedInfo={showExtendedChangeInfo}
									changedInformation={getChangedInformation(
										changedProps,
										'omfangTyp'
									)}
									child={
										<Form.Control
											as={'select'}
											defaultValue={getValues('omfangTyp')}
											type="select"
											className="form-select"
											placeholder={t('valjEllips')}
											{...register('omfangTyp')}
											isInvalid={!!errors.omfangTyp}
										>
											{getOptions('rangeTypes', true)}
										</Form.Control>
									}
								/>
							</Form.Group>
						</Col>
						{pageVariant !== 'quick' && watchMediatyp === 'Nedladdningsbar bok' && (
							<Col xs={12}>
								<Form.Group className="mb-3">
									<Form.Label className="mb-1 text-body">
										{t('epubversion')}
									</Form.Label>
									<Form.Control
										as={'select'}
										type="select"
										className="form-select"
										placeholder={t('valjEllips')}
										{...register('epubversion')}
										isInvalid={!!errors.epubversion}
									>
										{getOptions('epubVersions')}
									</Form.Control>
								</Form.Group>
							</Col>
						)}
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('illustrerad')}
								</Form.Label>
								<LockedOrChanged
									locked={shouldBeLocked(lockedFields, 'illustrerad')}
                                    changed={hasChanged(changedProps, 'illustrerad')}
                                    showExtendedInfo={showExtendedChangeInfo}
									changedInformation={getChangedInformation(
										changedProps,
										'illustrerad'
									)}
									child={
										<Form.Control
											as={'select'}
											type="select"
											className="form-select"
											placeholder={t('valjEllips')}
											{...register('illustrerad', {
												setValueAs: (v: any) => (v === 'true' || v === true)
											})}
											isInvalid={!!errors.illustrerad}
										>
											{getBooleanOptions()}
										</Form.Control>
									}
								/>
							</Form.Group>
						</Col>
						<Col xs={12} md={6}>
							<Form.Group className="mb-3">
								<Form.Label className="mb-1 text-body">
									{t('omslagsfarg')}
								</Form.Label>
								<LockedOrChanged
									locked={shouldBeLocked(lockedFields, 'omslagsfarg')}
                                    changed={hasChanged(changedProps, 'omslagsfarg')}
                                    showExtendedInfo={showExtendedChangeInfo}
									changedInformation={getChangedInformation(
										changedProps,
										'omslagsfarg'
									)}
									child={
										<Form.Control
											as={'select'}
											type="select"
											className="form-select"
											placeholder={t('valjEllips')}
											{...register('omslagsfarg')}
											isInvalid={!!errors.omslagsfarg}
										>
											{getOptions('coverColors')}
										</Form.Control>
									}
								/>
							</Form.Group>
						</Col>
						{pageVariant !== 'quick' && (
							<>
								<Col xs={12} md={6}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('bredd')}
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('bredd')}
											isInvalid={!!errors.bredd}
										/>
									</Form.Group>
								</Col>
								<Col xs={12} md={6}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('hojd')}
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('hojd')}
											isInvalid={!!errors.hojd}
										/>
									</Form.Group>
								</Col>
								<Col xs={12} md={6}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('ryggbredd')}
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('ryggbredd')}
											isInvalid={!!errors.ryggbredd}
										/>
									</Form.Group>
								</Col>
								<Col xs={12} md={6}>
									<Form.Group className="mb-3">
										<Form.Label className="mb-1 text-body">
											{t('vikt')}
										</Form.Label>
										<Form.Control
											placeholder={t('skrivEllips')}
											{...register('vikt')}
											isInvalid={!!errors.vikt}
										/>
									</Form.Group>
								</Col>
							</>
						)}
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
