import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFieldArray } from 'react-hook-form';
import { InformationIcon } from '../../shared/InformationIcon';
import {
    getBooleanOptions,
    getDragClass,
    getDragContainerClass,
    getItemStyle,
    getOptionsString,
} from '../common';
import { t } from '../../../services/translationService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { getAutoComplete } from '../../../services/autocompleteService';

interface Props {
    errors: any;
    register: any;
    control: any;
    getValues: any;
    setValue: any;
    trigger: any;
    blockRef: any;
    pageVariant: string;
}

export const General = ({
    errors,
    register,
    control,
    getValues,
    setValue,
    trigger,
    blockRef,
    pageVariant,
}: Props) => {
    const [miljomarkning, setMiljomarkning] = useState<string>('');
    const [tryckortList, setTryckortList] = useState<any>([]);
    const language = useAppSelector((state: any) => state.language.value);
    const [selectedTryckort, setSelectedTryckort] = useState<any>();
    const dispatch = useAppDispatch();

    const {
        fields: miljomarkningar,
        append: appendMiljomarkning,
        move: moveMiljomarkning,
        remove: removeMiljomarkning,
    } = useFieldArray({
        control,
        name: 'miljomarkningar',
    });


    useEffect(() => {
        let values = getValues('tryckort');
        if (values && values[0]?.key) {
            setSelectedTryckort(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedTryckort && selectedTryckort[0]?.key) {
            setValue('tryckort', selectedTryckort);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTryckort]);

    const addMiljomarkning = () => {
        if (miljomarkning !== '') {
            appendMiljomarkning(miljomarkning);
            setMiljomarkning('');
        }
    };

    const updateMiljomarkning = (event: ChangeEvent<HTMLInputElement>): void => {
        let value: string = (event.target as HTMLInputElement).value;
        setMiljomarkning(value);
    };

    const onMiljomarkningDragEnd = (result: any) => {
        moveMiljomarkning(result.source.index, result.destination.index);
    };

    const getSuggestions = useCallback(
        (queryString: string, type: string): void => {
            dispatch(setLoader(true));
            getAutoComplete(queryString, type, language)
                .then((data: any) => {
                    setTryckortList(data);
                    dispatch(setLoader(false));
                })
                .catch((err) => console.log(err));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <>
            <h3 ref={blockRef}>{t('ovrigInformation')}</h3>
            <Col xs={12}>
                <hr className="u-text-grey" />
            </Col>
            <Card className="nopadding my-4">
                <Card.Body className="p-3">
                    <Row>
                        <Col xs={7}>
                            <Form.Group as={Col} className="mb-3 col me-3">
                                <Form.Label className="mb-1 text-body">
                                    {t('tryckort')}
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name="tryckort"
                                    render={({ field, fieldState }) => (
                                        <AsyncTypeahead
                                            {...field}
                                            selected={ selectedTryckort }
                                            id="tryckort"
                                            labelKey={'value'}
                                            isLoading={false}
                                            options={tryckortList}
                                            placeholder={t('skrivEllips')}
                                            inputProps={{
                                                className: `${errors.tryckort ? 'is-invalid' : ''}`,
                                            }}
                                            filterBy={() => true}
                                            onSearch={(query: string) => {
                                                getSuggestions(query, 'PrintingPlaces');
                                            }}
                                            onChange={(selected: any) => {
                                                setSelectedTryckort(selected)
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <div className="d-flex align-items-end w-100">
                                <Form.Group as={Col} className="mb-3 col me-3">
                                    <Form.Label className="mb-1 text-body">
                                        {t('miljomarkning')}
                                    </Form.Label>
                                    <Form.Control
                                        as={'select'}
                                        type="select"
                                        className="form-select"
                                        placeholder={t('valjEllips')}
                                        value={miljomarkning}
                                        onChange={updateMiljomarkning}
                                    >
                                        {getOptionsString('environmentLabels')}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3 col-auto">
                                    <Button
                                        className="primary"
                                        type="button"
                                        onClick={addMiljomarkning}
                                    >
                                        <i className="bi bi-plus"></i>
                                    </Button>
                                </Form.Group>
                            </div>
                        </Col>
                        <DragDropContext onDragEnd={onMiljomarkningDragEnd}>
                            <Droppable droppableId="droppablegymnasieprogram">
                                {(provided: any, snapshot: any) => (
                                    <Col
                                        xs={12}
                                        className={`mb-3 ${getDragContainerClass(
                                            snapshot.isDraggingOver
                                        )}`}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {miljomarkningar.map((row: any, index: number) => {
                                            return (
                                                <Draggable
                                                    key={row.id}
                                                    draggableId={row.id}
                                                    index={index}
                                                >
                                                    {(provided: any, snapshot: any) => (
                                                        <Row
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            key={row.id}
                                                            className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                                                } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                                                        >
                                                            <Col
                                                                xs={11}
                                                                className="d-flex align-items-center"
                                                            >
                                                                {getValues(`miljomarkningar.${index}`)}
                                                            </Col>
                                                            <Col xs={1} className="d-flex align-items-center">
                                                                <i
                                                                    className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                                                    onClick={() => removeMiljomarkning(index)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                        {provided.placeholder}
                                    </Col>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Col xs={12}>
                            <hr className="u-text-grey" />
                        </Col>
                        <Col xs={7}>
                            <Row>
                                {pageVariant === 'large' && (
                                    <Col xs={6}>
                                        <Form.Group as={Col} className="mb-3 col me-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('ceMarkning')}
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                {...register('ceMarkning', {
                                                    setValueAs: (v: string) => (v === 'true')
                                                })}
                                            >
                                                {getBooleanOptions()}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col xs={pageVariant === 'large' ? 6 : 12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label className="mb-1 text-body">
                                            {t('prisgrupp')}
                                            <InformationIcon
                                                text={t('hjalpPrisgrupp')}
                                                inline
                                                className="ps-1"
                                            />
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={t('skrivEllips')}
                                            {...register('prisgrupp')}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};
