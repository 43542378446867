import { useRef, useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Publisher } from './Publisher';
import { Large } from './Large';
import { Quick } from './Quick';
import { SideMenu } from './SideMenu';
import { t } from '../../services/translationService';

declare global {
    interface Bokinfo {
        book: any;
        dictionaries: any;
        lockedProperties: any;
        updatedFields: any;
        publisherLimitations: boolean;
        isAdmin: boolean;
        showUploadControl: boolean;
        mainDistributor: number;
    }
}

export const EditBook = () => {
    const [admin, setAdmin] = useState(
        window.bokinfo.isAdmin
    );
    const quickRefs = useRef([]);
    const largeRefs = useRef([]);
    const publisherRefs = useRef([]);
    const [tab, setTab] = useState('quick');
    const [publisher, setPublisher] = useState(
        window.bokinfo.publisherLimitations
    );
    const [book, setBook] = useState(
        window.bokinfo?.book
    );
    const [reviewed, setReviewed] = useState(window.bokinfo.book?.granskad || false);

    const dates: any = window.bokinfo.dates;

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-9">
                    {!publisher && (
                        <>
                            <Nav
                                variant="tabs"
                                className="mb-3"
                                role="tablist"
                            >
                                <Nav.Item>
                                    <Nav.Link className={tab == 'quick' ? 'active' : ''} aria-selected={tab == 'quick'} onClick={(e:any) => { e.stopPropagation(); setTab('quick')}}>{t('snabben')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className={tab == 'large' ? 'active' : ''} aria-selected={tab == 'large'} onClick={(e) => {e.stopPropagation(); setTab('large')}}>{t('storen')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </>
                    )}
                    <div className="d-flex align-items-start">
                        <h1 className="h2 u-max-width-39 pe-4">{window.bokinfo?.book?.titel}</h1>
                        <a
                            href={window.bokinfo.book?.boksidaUrl}
                            className="btn btn-secondary u-fixed-width-185 mt-2 ms-auto"
                        >
                            {t('tillBoksida')} <i className="bi bi-arrow-right-short" />
                        </a>
                    </div>
                    {!publisher ? (
                        <div>
                            {dates?.created != null ? (
                                <div className="card small p-3 mt-3 mb-1 u-text-grey">
                                    <div className="row d-flex align-items-start justify-content-between">
                                        <div className="col-sm-6">
                                            <label>{t('skapad')}:</label>
                                            <div className="ps-1 d-inline">{dates.created}</div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{t('andrad')}:</label>
                                            <div className="ps-1 d-inline">{dates.modified}</div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{t('distinfo')}:</label>
                                            <div className="ps-1 d-inline">{dates.distributorPriceChange}</div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{t('distributor')}:</label>
                                            <div className="ps-1 d-inline">{window.bokinfo.distributor}</div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{t('fakturadatum')}:</label>
                                            <div className="ps-1 d-inline">{dates.invoice}</div>
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{t('forlag')}:</label>
                                            <div className="ps-1 d-inline">{book.forlagsnamn}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {tab === 'quick' ? (
                                <Quick blockRefs={quickRefs} reviewed={reviewed} />
                            ) : (
                                <Large blockRefs={largeRefs} reviewed={reviewed} />
                            )}
                        </div>
                    ) : (
                        <Publisher blockRefs={publisherRefs} />
                    )}
                </div>
                <div className="col-md-3">
                    <SideMenu
                        blockRefs={
                            publisher
                                ? publisherRefs
                                : tab === 'quick'
                                    ? quickRefs
                                    : largeRefs
                        }
                        isPublisher={publisher}
                        isAdmin={admin}
                        currentForm={publisher ? 'publisher' : tab}
                        reviewed={reviewed}
                        setReviewed={setReviewed}
                        setTab={setTab}
                        tab={tab}
                    />
                </div>
            </div>
        </div>
    );
};
