import { useCallback, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray } from 'react-hook-form';
import { ChangedProperty, keyValue } from '../../../redux/types/types';
import {
	getDragClass,
	getDragContainerClass,
	getItemStyle,
	shouldBeLocked,
} from '../common';
import { t } from '../../../services/translationService';
import { getAutoComplete } from '../../../services/autocompleteService';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

interface Props {
	errors: any;
	register: any;
	control: any;
	getValues: any;
	setValue: any;
	trigger: any;
	blockRef: any;
	pageVariant: string;
	lockedFields: keyValue[];
	changedProps: ChangedProperty[];
}

export const Language = ({
	control,
	getValues,
	blockRef,
	lockedFields,
	errors,
}: Props) => {
	const {
		fields: sprak,
		append: appendSprak,
		move: moveSprak,
		remove: removeSprak,
	} = useFieldArray({
		control,
		name: 'sprak',
		rules: { minLength: 1, required: t('valideringSprak') },
	});

	const {
		fields: originalsprak,
		append: appendOriginalsprak,
		move: moveOriginalsprak,
		remove: removeOriginalsprak,
	} = useFieldArray({
		control,
		name: 'originalsprak',
	});

	const [language, setLanguage] = useState<any>([]);
	const [languageList, setLanguageList] = useState<any>([]);
	const [originalLanguage, setOriginalLanguage] = useState<string>('');
	const [originalLanguageList, setOriginalLanguageList] = useState<any>([]);
	const currentLanguage = useAppSelector((state: any) => state.language.value);
	const dispatch = useAppDispatch();
	const languageRef = useRef<Typeahead>(null);
	const originalLanguageRef = useRef<Typeahead>(null);

	const onLanguagesDragEnd = (result: any) => {
		moveSprak(result.source.index, result.destination.index);
	};

	const onOriginalLanguagesDragEnd = (result: any) => {
		moveOriginalsprak(result.source.index, result.destination.index);
	};

	const addLanguage = () => {
		appendSprak(language);
		languageRef.current?.clear();
		setLanguage([]);
	};

	const addOriginalLanguage = () => {
		if(originalLanguage !== '') {
			appendOriginalsprak(originalLanguage);
			originalLanguageRef.current?.clear();
			setOriginalLanguage('');
		}
	};

	const getSuggestions = useCallback(
		(queryString: string, type: string, isOriginal: boolean): void => {
			dispatch(setLoader(true));
			getAutoComplete(queryString, type, currentLanguage)
				.then((data: any) => {
					if (isOriginal) {
						setOriginalLanguageList(data);
					} else {
						setLanguageList(data);
					}

					dispatch(setLoader(false));
				})
				.catch((err) => console.log(err));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<>
			<h3 ref={blockRef}>{t('sprak')}</h3>
			<Col xs={12}>
				<hr className="u-text-grey" />
			</Col>
			<Card className="nopadding my-4">
				<Card.Body className="p-3">
					<Row>
						<Col xs={12}>
							<div className="d-flex align-items-end flex-wrap w-100">
								<Form.Group as={Col} className="col me-3">
									<Form.Label className="mb-1 text-body">
										{t('sprak')}*
									</Form.Label>
									{!shouldBeLocked(lockedFields, 'sprak') && (
										<AsyncTypeahead
											id="sprak"
											labelKey="value"
											isLoading={false}
											options={languageList}
											placeholder={t('valjEllips')}
											ref={languageRef}
											disabled={shouldBeLocked(lockedFields, 'sprak')}
											inputProps={{
												className: `${errors.sprak?.root ? 'is-invalid' : ''}`,
											}}
											filterBy={() => true}
											onSearch={(query: string) => {
												getSuggestions(query, 'language', false);
											}}
											onChange={(selected: any) => {
												setLanguage(selected);
											}}
										/>
									)}
								</Form.Group>
								{!shouldBeLocked(lockedFields, 'sprak') && (
									<Form.Group as={Col} className="col-auto">
										<Button
											className="primary"
											type="button"
											onClick={addLanguage}
										>
											<i className="bi bi-plus"></i>
										</Button>
									</Form.Group>
								)}
								<Col sm={12}>
									{errors.sprak?.root && (
										<Form.Control.Feedback
											className="d-block w-100"
											type="invalid"
										>
											{`${errors.sprak?.root.message}`}
										</Form.Control.Feedback>
									)}
								</Col>
							</div>
						</Col>
						<DragDropContext onDragEnd={onLanguagesDragEnd}>
							<Droppable droppableId="droppableSprak">
								{(provided: any, snapshot: any) => (
									<Col
										xs={12}
										className={`mb-3 ${getDragContainerClass(
											snapshot.isDraggingOver
										)}`}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{sprak.map((row: any, index: number) => {
											return (
												<Draggable
													key={row.id}
													draggableId={row.id}
													index={index}
													isDragDisabled={shouldBeLocked(
														lockedFields,
														'sprak'
													)}
												>
													{(provided: any, snapshot: any) => (
														<Row
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style
															)}
															key={row.id}
															className={`${
																index % 2 === 1 ? 'u-background-grey' : ''
															} mt-2 p-1 ${getDragClass(
																snapshot.isDragging
															)}`}
														>
															<Col
																xs={11}
																className="d-flex align-items-center"
															>
																{getValues(`sprak.${index}.value`)}
															</Col>
															<Col
																xs={1}
																className="d-flex align-items-center"
															>
																{!shouldBeLocked(lockedFields, 'sprak') && (
																	<i
																		className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																		onClick={() => removeSprak(index)}
																	/>
																)}
															</Col>
														</Row>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Col>
								)}
							</Droppable>
						</DragDropContext>
						{sprak.length === 0 && (
							<p className="text-muted">Inga språk tillagda</p>
						)}
						<Col xs={12}>
							<hr className="u-text-grey" />
						</Col>
						<Col xs={12}>
							<div className="d-flex align-items-end w-100">
								<Form.Group as={Col} className="mb-3 col me-3">
									<Form.Label className="mb-1 text-body">
										{t('originalSprak')}
									</Form.Label>
									{!shouldBeLocked(lockedFields, 'originalsprak') && (
										<AsyncTypeahead
											id="originalsprak"
											labelKey="value"
											isLoading={false}
											options={originalLanguageList}
											placeholder={t('valjEllips')}
											ref={originalLanguageRef}
											disabled={shouldBeLocked(lockedFields, 'originalSprak')}
											filterBy={() => true}
											onSearch={(query: string) => {
												getSuggestions(query, 'language', true);
											}}
											onChange={(selected: any) => {
												setOriginalLanguage(selected);
											}}
										/>
									)}
								</Form.Group>
								{!shouldBeLocked(lockedFields, 'originalsprak') && (
									<Form.Group as={Col} className="mb-3 col-auto">
										<Button
											className="primary"
											type="button"
											onClick={addOriginalLanguage}
										>
											<i className="bi bi-plus"></i>
										</Button>
									</Form.Group>
								)}
							</div>
						</Col>
						<DragDropContext onDragEnd={onOriginalLanguagesDragEnd}>
							<Droppable droppableId="droppableSprak">
								{(provided: any, snapshot: any) => (
									<Col
										xs={12}
										className={`mb-3 ${getDragContainerClass(
											snapshot.isDraggingOver
										)}`}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{originalsprak.map((row: any, index: number) => {
											return (
												<Draggable
													key={row.id}
													draggableId={row.id}
													index={index}
													isDragDisabled={shouldBeLocked(
														lockedFields,
														'originalSprak'
													)}
												>
													{(provided: any, snapshot: any) => (
														<Row
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style
															)}
															key={row.id}
															className={`${
																index % 2 === 1 ? 'u-background-grey' : ''
															} mt-2 p-1 ${getDragClass(
																snapshot.isDragging
															)}`}
														>
															<Col
																xs={11}
																className="d-flex align-items-center"
															>
																{getValues(`originalsprak.${index}.value`)}
															</Col>
															<Col
																xs={1}
																className="d-flex align-items-center"
															>
																{!shouldBeLocked(lockedFields, 'originalsprak') && (
																	<i
																		className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
																		onClick={() => removeOriginalsprak(index)}
																	/>
																)}
															</Col>
														</Row>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Col>
								)}
							</Droppable>
						</DragDropContext>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};
