import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Modal,
    Row,
    Tooltip,
    OverlayTrigger,
    FormCheck,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useFieldArray } from 'react-hook-form';
import { StatusIcon } from '../../shared/StatusIcon';
import { Table } from '../../shared/Table';
import { t } from '../../../services/translationService';
import { InformationIcon } from '../../shared/InformationIcon';
import { getOptions, getOptionsLabel } from '../common';

interface Props {
    errors: any;
    register: any;
    control: any;
    getValues: any;
    setValue: any;
    trigger: any;
    blockRef: any;
    pageVariant: string;
}

export const PriceAndAvailability = ({
    errors,
    register,
    control,
    getValues,
    setValue,
    trigger,
    blockRef,
}: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);
    const [forvaldDistributor, setForvaldDistributor] = useState<string>(getValues('forvaldDistributorId'));

    const {
        fields: priser,
        append: appendPris,
        remove: removePris,
        update: updatePris,
    } = useFieldArray({
        control,
        name: 'priser',
    });

    const savePrice = () =>
        trigger(
            `priser.${currentIndex !== undefined ? currentIndex : priser.length - 1}`
        ).then((validResult: boolean) => {
            if (validResult) {
                const list = window.bokinfo.dictionaries['distributors'];
                const key =
                    currentIndex !== undefined ? currentIndex : priser.length - 1;
                const result = list.filter(
                    (obj: any) =>
                        obj.key.toString() ===
                        getValues(`priser.${key}.distributor`).toString()
                );
                const last = getValues(`priser.${priser.length - 1}`);
                currentIndex === undefined && removePris(priser.length - 1);

                if (currentIndex === undefined) {
                    appendPris({
                        ...last,
                        forvantadleverans: last.forvantadleverans && new Date(last.forvantadleverans).toLocaleDateString('sv-SE'),
                        lagersaldo: last.lagersaldo === "" ? null : last.lagersaldo
                    });
                } else {
                    const price = getValues(`priser.${currentIndex}`);

                    price.lagersaldo = price.lagersaldo === "" ? null : price.lagersaldo;

                    updatePris(currentIndex, price);
                }

                const prices = getValues(`priser`);
                if (prices.length === 1) {
                    setValue('forvaldDistributorId', prices[0].distributor);
                }
                setShowModal(false);
            }
        });

    const displayModal = (index?: number) => {
        index === undefined ? appendPris({}) : setCurrentIndex(index);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        currentIndex === undefined && removePris(priser.length - 1);
    };

    const datepickerInvalid = (index: number, key: string) => {
        return !!errors.priser && errors.priser[index][key] ? 'is-invalid' : '';
    };

    const columns = [
        { id: 0, title: t('distributor'), width: '20%', truncated: true },
        { id: 1, title: t('lagerstatus'), width: '20%' },
        { id: 2, title: t('pris'), width: '10%' },
        { id: 3, title: t('pristyp'), width: '20%' },
        { id: 4, title: t('startdatum'), width: '15%' },
        { id: 4, title: t('slutdatum'), width: '15%' },
        { id: 5, title: '', width: '10%' },
    ];

    const priceTypes = window.bokinfo.dictionaries['priceTypes'];

    const connectedDistributors = window.bokinfo.dictionaries['connectedDistributors'];

    useEffect(() => {
        const index: number = priser.findIndex((p: any) => p.distributor === forvaldDistributor);

        // only shift if the selected price is not the first one
        if (index > 0) {
            let [price] = priser.splice(index, 1);
            priser.unshift(price);
        }
    }, [forvaldDistributor, priser]);

    return (
        <>
            <h3 ref={blockRef} className="d-inline-block">
                {t('prisOchTillganglighet')}
            </h3>
            <Col xs={12}>
                <hr className="u-text-grey" />
            </Col>
            <Card className="mt-3 nopadding">
                <Card.Header>
                    <span className="py-3 m-0 d-inline-block u-text-small-medium">
                        {t('forvaldDistributor')}:{' '}
                        {(priser.filter((p: any) => p.distributor === getValues('forvaldDistributorId')) as any)[0]?.distributor ? getOptionsLabel('distributors', (priser.filter((p: any) => p.distributor === getValues('forvaldDistributorId')) as any)[0]?.distributor) : '-'}
                    </span>
                </Card.Header>
                <Card.Body>
                    <Table
                        smallTableHeadFont
                        firstColumnBold={false}
                        striped
                        fallback={t('ingaPriserTillagda')}
                        columns={columns}
                        items={priser
                            .filter((r: any) => {
                                return r.moms !== '' && r.moms !== undefined && r.moms !== null
                            })
                            .map(
                                (
                                    {
                                        distributor,
                                        lagerstatus,
                                        forvantadleverans,
                                        pris,
                                        pristyp,
                                        startdatum,
                                        slutdatum,
                                    }: any,
                                    index: number
                                ) => ({
                                    columns: [
                                        <>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 100, hide: 400 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>{getOptionsLabel('distributors', distributor)}</Tooltip>
                                                )}
                                            >
                                                <span>
                                                    {getOptionsLabel('distributors', distributor)}
                                                </span>
                                            </OverlayTrigger>

                                        </>,
                                        <>
                                            <Col className="d-flex flex-direction-row align-items-center">
                                                <StatusIcon status={lagerstatus} label />
                                                {forvantadleverans &&
                                                    (lagerstatus === 'OutOfStock' || lagerstatus === 'TemporaryOutOfStock') && (
                                                        <InformationIcon
                                                            inline
                                                            text={`Förväntad leverans: ${forvantadleverans}`}
                                                            className="ps-2"
                                                        />
                                                    )}
                                            </Col>
                                        </>,
                                        pris,
                                        <span className="text-truncate d-block">
                                            {priceTypes.filter((p: any) => p.key === pristyp)[0]?.value ?? ""}

                                        </span>,
                                        startdatum && !startdatum.startsWith("0001-01-01") &&
                                        new Date(startdatum).toLocaleDateString('sv-SE'),
                                        slutdatum &&
                                        new Date(slutdatum).toLocaleDateString('sv-SE'),
                                        <div className="d-flex gap-2">
                                            <InformationIcon
                                                inline
                                                text={`TODO begränsningar`}
                                                regularSize
                                            />
                                            <i
                                                className="bi bi-pencil u-text-primary u-cursor-pointer"
                                                onClick={() => displayModal(index)}
                                            />
                                            <i
                                                className="bi bi-trash3 u-text-error u-cursor-pointer"
                                                onClick={() => removePris(index)}
                                            />
                                        </div>,
                                    ],
                                })
                            )}
                    />
                </Card.Body>
                <Card.Footer>
                    <Button
                        type="button"
                        className="primary"
                        onClick={() => {
                            displayModal(undefined);
                        }}
                    >
                        <i className="bi bi-plus" />
                        {t('laggTillPris')}
                    </Button>
                </Card.Footer>
            </Card>
            <Modal
                show={showModal}
                centered
                size="lg"
                onHide={closeModal}
                onExited={() => setCurrentIndex(undefined)}
            >
                <Modal.Body>
                    {priser.map((row: any, index: number) => {
                        const showSetForvaldDistributor = connectedDistributors?.some((item: { key: any; }) => item.key === row.distributor) ?? false;

                        return (
                            index ===
                            (currentIndex !== undefined
                                ? currentIndex
                                : priser.length - 1) && (
                                <Row key={index}>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            {showSetForvaldDistributor &&
                                                <FormCheck
                                                    label="Förvald distributör"
                                                    className="mb-3"
                                                    checked={row.distributor === forvaldDistributor}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setForvaldDistributor(row.distributor);
                                                            setValue('forvaldDistributorId', row.distributor);
                                                        }
                                                    }}
                                                />
                                            }
                                            <Form.Label className="mb-1 text-body">
                                                {t('distributor')}*
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                {...register(`priser.${index}.distributor`, {
                                                    required: t('valideringDistributor'),
                                                })}
                                                isInvalid={
                                                    !!errors.priser && errors.priser[index].distributor
                                                }
                                            >
                                                {getOptions('distributors')}
                                            </Form.Control>
                                            {getValues(`priser.${currentIndex}.distributor`)?.key}
                                            {!!errors.priser && errors.priser[index].distributor && (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.priser[index].distributor.message}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('pristyp')}*
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                {...register(`priser.${index}.pristyp`,
                                                    { required: t('valideringPriceType') }
                                                )}
                                                isInvalid={
                                                    !!errors.priser && errors.priser[index].pristyp
                                                }
                                            >
                                                {getOptions('priceTypes')}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {t('valideringPriceType')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('pris')}*
                                            </Form.Label>
                                            <Form.Control
                                                placeholder={t('skrivEllips')}
                                                {...register(`priser.${index}.pris`, {
                                                    required: t('valideringPris'),
                                                })}
                                                type={'number'}
                                                isInvalid={!!errors.priser && errors.priser[index].pris}
                                            />
                                            {!!errors.priser && errors.priser[index].pris && (
                                                <Form.Control.Feedback type="invalid">
                                                    {`${errors.priser[index].pris.message}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('moms')}*
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                isInvalid={!!errors.priser && errors.priser[index].moms}
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                {...register(`priser.${index}.moms`, {
                                                    required: t('valideringMoms'),
                                                })}
                                            >
                                                {getOptions('taxes')}
                                            </Form.Control>
                                            {!!errors.priser && errors.priser[index].moms && (
                                                <Form.Control.Feedback type="invalid">
                                                    {`${errors.priser[index].moms.message}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('startdatum')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name={`priser.${index}.startdatum`}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        dateFormat={'yyyy-MM-dd'}
                                                        placeholderText={t('yyyymmdd')}
                                                        locale="sv"
                                                        onChange={(date) =>
                                                            field.onChange(date?.toLocaleDateString('sv-SE'))
                                                        }
                                                        selected={
                                                            !field.value || field.value.startsWith("0001-01-01") ? null : new Date(field.value)
                                                        }
                                                        className={`form-control ${datepickerInvalid(
                                                            index,
                                                            'startdatum'
                                                        )}`}
                                                    />
                                                )}
                                            />
                                            {!!errors.priser && errors.priser[index].startdatum && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                >
                                                    {`${errors.priser[index].startdatum.message}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('slutdatum')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name={`priser.${index}.slutdatum`}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        dateFormat={'yyyy-MM-dd'}
                                                        placeholderText={t('yyyymmdd')}
                                                        locale="sv"
                                                        onChange={(date) =>
                                                            field.onChange(date?.toLocaleDateString('sv-SE'))
                                                        }
                                                        selected={
                                                            !field.value ? field.value : new Date(field.value)
                                                        }
                                                        className={`form-control ${datepickerInvalid(
                                                            index,
                                                            'slutdatum'
                                                        )}`}
                                                    />
                                                )}
                                            />
                                            {!!errors.priser && errors.priser[index].slutdatum && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                >
                                                    {`${errors.priser[index].slutdatum.message}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <hr className="u-text-grey" />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('lagerstatus')}*
                                            </Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                type="select"
                                                className="form-select"
                                                placeholder={t('valjEllips')}
                                                {...register(`priser.${index}.lagerstatus`, {
                                                    required: t('valideringLagerstatus'),
                                                })}
                                                isInvalid={!!errors.priser && errors.priser[index].lagerstatus}
                                            >
                                                {getOptions('stockStatuses')}
                                            </Form.Control>
                                            {!!errors.priser && errors.priser[index].lagerstatus && (
                                                <Form.Control.Feedback type="invalid">
                                                    {`${errors.priser[index].lagerstatus.message}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('forvantadLeverans')}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name={`priser.${index}.forvantadLeverans`}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        dateFormat={'yyyy-MM-dd'}
                                                        placeholderText={t('yyyymmdd')}
                                                        locale="sv"
                                                        onChange={(date) =>
                                                            field.onChange(date?.toLocaleDateString('sv-SE'))
                                                        }
                                                        selected={
                                                            !field.value ? field.value : new Date(field.value)
                                                        }
                                                        className={`form-control ${datepickerInvalid(
                                                            index,
                                                            'forvantadLeverans'
                                                        )}`}
                                                    />
                                                )}
                                            />
                                            {!!errors.priser &&
                                                errors.priser[index].forvantadleverans && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="d-block"
                                                    >
                                                        {`${errors.priser[index].forvantadleverans.message}`}
                                                    </Form.Control.Feedback>
                                                )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('lagersaldo')}
                                            </Form.Label>
                                            <Form.Control
                                                placeholder={t('skrivEllips')}
                                                type="number"
                                                {...register(`priser.${index}.lagersaldo`)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className="mb-1 text-body">
                                                {t('uppdaterad')}
                                            </Form.Label>
                                            <Form.Control
                                                readOnly
                                                plaintext
                                                value={
                                                    isNaN(
                                                        Date.parse(
                                                            getValues(
                                                                `priser.${index}.uppdateradAvDistributor`
                                                            )
                                                        )
                                                    )
                                                        ? ''
                                                        : new Date(
                                                            getValues(
                                                                `priser.${index}.uppdateradAvDistributor`
                                                            )
                                                        ).toLocaleDateString('sv-SE') + ' ' +
                                                        new Date(
                                                            getValues(
                                                                `priser.${index}.uppdateradAvDistributor`
                                                            )
                                                        ).toLocaleTimeString('sv-SE', { timeStyle: "short" })
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {t('avbryt')}
                    </Button>
                    <Button variant="primary" onClick={savePrice}>
                        {t('spara')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
